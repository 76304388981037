"use client";
import dynamic from "next/dynamic";
import { useInteraction } from "@/helpers/useInteraction";

const PromoBadge = dynamic(() => import("@/components/banners/PromoBadge"), { ssr: false });
const PromoBadgeWrap = () => {
  const { isInteracted } = useInteraction();

  return isInteracted ? <PromoBadge /> : null;
};

export default PromoBadgeWrap;
