import(/* webpackMode: "eager" */ "/vercel/path0/components/banners/PromoBadgeWrap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/head/StructuredData.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/Footer/SiteFooter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/Header/SiteHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/PreviewAlert.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/homepage/SectionHomepageSeo.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/homepage/SectionHomepageFears.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/homepage/SectionHomepageSteps.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/homepage/SectionHomepageHire.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/AccordionItem.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/AccordionItemWithEditorContent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/PriceCalculator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/review/ReviewBadges.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/review/ReviewItem.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/review/Rating.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionFaq.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/cards/NumberedCard.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionFeatures.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/cards/FreeFeaturesCard.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/PaymentIcons.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionOrder.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionTabList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/SimpleContentParser.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sliders/ReviewsSlider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sliders/WritersSlider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/typography/Title.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/Buttons/Button.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/Buttons/ButtonSpinner.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/typography/ListItem.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/LazySvgSpriteIcon.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/Tooltips/Popover.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionPayments.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionReviews.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/sections/SectionWriters.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/WrappedIcon.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/icons/svg/angle-right-b.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/icons/svg/receipt-alt.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/modules/card.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/critical/_section-promo.scss")